import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";

import { News } from "./news";

interface newsInfo {
  img: string;
  title: string;
  shortDesc: string;
  longDesc?: string;
  link?: string;
}

export default function NewsSection() {
  const data: newsInfo[] = [
    // {
    //   img: "/medlem.jpeg",
    //   title: "Öppet hus",
    //   shortDesc: `Öppet hus 11/1: två pass, 18-19 och 19:30-20:30 med hjälp från instruktör.`,
    // },
    // {
    //   img: "/medlem.jpeg",
    //   title: "Hallen öppen",
    //   shortDesc: `Nu är hallen äntligen öppen. Välkommen att boka kompisaktivitet eller företagsevent. Eller om du vill ha en ny aktivitet är alla välkomna att bli medlem. Oavsett är info@umecurling.se din kontaktpunkt med oss.`,
    // link: "https://www.curling.se/tavla/seriespel/elitserien-herrar-2023-2024/spelprogram-och-resultat"
    // },
    {
      img: "/medlem.jpeg",
      title: "Förlängt öppet",
      shortDesc: `Hallen är öppen längre än vanligt. Till och med 13/4.`,
      
    },
  ];

  return (
    <Container>
        <Box sx={{ margin: "auto", width: {sx: "100%", md: "75%"} }}>
        <Grid
          alignItems={"center"}
          container
          justifyContent={"center"}
          direction="column"
          sx={{ mt: "20px", width: "100%"}}
        >
          <Typography
            variant="h3"
            sx={{
              fontFamily: "BlinkMacSystemFont",
              color: "text.secondary",
              fontWeight: 600,
            }}
          >
            Nyheter
          </Typography>
          <Stack
            sx={{
              my: "20px",
              mx: { sm: "0px", md: "10px" },
              py: "10px",
            }}
            direction="row"
          >
            {data.map((news) => (
              <News {...news} key={news.title}></News>
            ))}
          </Stack>
        </Grid>
      </Box>
    </Container>
  );
}
